import * as React from "react"
import Feature from "../components/home/feature"
import Layout from "../components/layout"

const Produit = () => {

  const getWidth = () => {
    if (typeof window !== 'undefined') {
      return window.innerWidth;
    }
    return;
  }

  const features = [
    {
      title: "Calendriers",
      description:
        "Via son module Calendriers,  SmartOp ambitionne de diffuser rapidement et efficacement les disponibilités de tous les soignants via une interface hautement ergonomique et éviter les imprévus RH ainsi que les problèmes de transmission de l’information.<br>De même, SmartOp a pour objectif de toujours donner une vision moyen et long terme de ses effectifs afin d’être certain de pouvoir assurer l’ensemble des programmes opératoires.",
      features: [
        "Gestions des effectifs : désidératas, congés, gardes",
        "Gestions des besoins",
        "Gestions des souhaits de distribution de temps",
      ],
      img: "hrsuite",
    },
    {
      title: "Smartplanning",
      description:
        "Avec smartplanning, plus besoin de s’épuiser à composer les équipes en salles. L’outil vous accompagne dans la meilleure association de compétences en fonction des programmes opératoires et en respectant au mieux les souhaits des soignants.<br>L’outil apprend de votre expérience et deviendra indispensable dans le quotidien de la gestion du bloc.",
      features: [
        "Composition des équipes en salles en fonction des compétences",
        "Associations des médecins anesthésistes-réanimateurs en fonction de leurs souhaits de travail",
        "Planning de la semaine configuré en moins de 10 minutes",
      ],
      img: "smartplanning",
    },
    {
      title: "Parcours Ibode (1/2)",
      description:
        "Smartop met la data au service de la progression et de l’émulation collective à travers son module Parcours Ibode.<br>SmartOp pousse les IDE à développer des compétences sur l’ensemble des spécialités  du bloc mais peut s’adapter en cas de spécialisation d’équipe.<br>L’outil permet la création des équipes en fonction des programmes opératoires en optimisant la progression générale du groupe 7x plus rapide que la tendance en place.",
      features: [
        "Emulation collective et progression générale du groupe infirmier 7 fois plus rapide",
        "Emulation individuelle via un suivi continu des compétences",
        "Dashboard de suivi d’activité dans le bloc et de progression individuelle générale et par spécialité",
      ],
      img: "parcoursIbode1",
    },
    {
      title: "Parcours Ibode (2/2)",
      description:
        "SmartOp se positionne comme un outil métier et comprend l’importance de la diffusion du contenu médical.<br> SmartOp capitalise sur son équipe de médecins pour consolider les informations associées aux interventions et les exploiter grâce à l’enrichissement de contenu médical. SmartOp devient donc un partenaire indispensable de la formation.",
      features: [
        "Numérisation des books d’interventions",
        "Listing du matériel à préparer pour les prochains programmes",
        "Mise à disposition intelligente du contenu aux soignants en fonction de leurs programmes opératoires",
      ],
      img: "livret-intervention",
    },
    {
      title: "StatOp",
      description:
        "La data au service de l’efficacité du bloc.<br>Via son module StatOp, SmartOp vous accompagne dans le suivi d’activité et d’efficacité de votre bloc opératoire en se basant sur les indicateurs ANAP.<br>L’outil vous accompagne dans la détection de points clés d’améliorations structurelles de votre organisation.",
      features: [
        "Suivi en temps réel des interventions et de l’ensemble des métriques ANAP avec une expérience utilisateur perfectionnée",
        "Croisement des informations sur plusieurs clés de lecture : par salle , par spécialité",
        "Estimation de niveau de risque de dépassement des programmes et accompagnement dans la redistribution des interventions dans les salles",
        "Récapitulatif d’activité de bloc en prévision des comités trimestrielles et pistes d’améliorations",
      ],
      img: "statop",
    },
    {
      title: "Messagerie professionnelle",
      description:
        "SmartOp vous accompagne dans la communication de l’ensemble des soignants. L’objectif de cette messagerie est de centraliser l’ensemble des échanges associés à la gestion du bloc opératoire afin d’éviter de perdre de l’information du fait de la multiplication des canaux d’échanges.<br>Cette messagerie est reliée à la gestion de vos besoins en effectif et vous accompagne dans la recherche de vacataires.",
      features: [
        "Echanges autour du bloc centralisés et unifiés autour d’une messagerie professionnelle sécurisée",
        "Accompagnement dans la recherche et la relance de vacataires",
      ],
      img: "messagerie",
    },
    {
      title: "Environnement d’utilisation",
      description:
        "SmartOp est disponible sur web ( Chrome, Mozilla & Edge) ainsi que sur mobile (Android & iOS).<br>SmartOp tire le maximum de l’environnement mobile pour dynamiser la transmission de l’information.",
      features: [
        "Support web & mobile pour l’ensemble des soignants",
        "Utilisation des notifications push pour dynamiser la transmission de l’information",
      ],
      img: "smartop-mobile",
      imgStyle: { minWidth: "50%" },
    },
    {
      title: "Connectivité & interopérabilité",
      description:
        "SmartOp croit fortement dans la richesse que peut apporter l’échange d’informations entre logiciels. C’est pourquoi SmartOp est muni de connecteurs pour pouvoir s’interfacer avec les logiciels déjà existants.",
      features: [
        "Disponibilités de nombreux exports, pdf & csv",
        "API sortantes existantes afin d’exploiter la donnée produite.",
      ],
      img: "connectivite",
    },
    {
      title: "Health compliance & Sécurité des données",
      description:
        "SmartOp a mis en place l’ensemble des processus nécessaires pour rendre sa plateforme totalement sécurisée.<br>Aucune donnée personnelle patient  n’est n’utilisée. L’ensemble des modélisations se font sur des données anonymisées.",
      features: [
        "Hébergement des données sur un serveur certifié HDS",
        "Transmissions d’informations hautement sécurisées",
        "Certification HIPAA/ HITRUST dans le monde",
        "Aucune donnée patient n’est stockée dans l’application",
        "Les modélisations se font sur des informations anonymisées",
      ],
      img: "security",
      imgStyle: { minWidth: "44%" },
    },
  ].map((feature, i) => {
    const { title, features, img, description, imgStyle } = feature
    const imgPath = "/features/" + img + "-710.png"
    return (
      <Feature
        title={title}
        description={description}
        features={features}
        img={imgPath}
        textInLeft={getWidth() <= 900 ? true :  i % 2 === 0}
        imgStyle={imgStyle}
      />
    )
  })
  return (
    <Layout>
      <div id="mainContent_div" className="mainContent produit">
        {features}{" "}
      </div>
    </Layout>
  )
}

export default Produit
