import * as React from "react"

const Feature = ({
                   img,
                   title,
                   description,
                   features,
                   textInLeft,
                   imgStyle
                 }) => {
  let textClass = "left-details"
  if (!textInLeft) {
    textClass = "right-details"
  }
  let left = (
    <div class={textClass}>
      <h2 class="title">{title}</h2>
      <div class="mg-r-96">
        <p class="description" dangerouslySetInnerHTML={{ __html: description }}></p>
        <ul role="list" class="list w-list-unstyled">
          <li key={"uniqueID"} class="liste-fonctionnalites">
            {features.map(feature => {
              return (
                <div class="feature-item">
                  <div class="check-img-wrapper">
                    <img alt={title} src="/check_list.svg" width={17} height={17} style={{ marginTop: "3px" }} />
                  </div>
                  <div class="features">{feature}</div>
                </div>
              )
            })}
          </li>
        </ul>
      </div>
    </div>
  )
  let right = (
    <img
      style={imgStyle}
      src={img}
      alt={title}
      class=" screenshot shadow-outside"
    />
  )
  if (!textInLeft) {
    let intermediary = right
    right = left
    left = intermediary
  }
  return (
    <div className="">
      <div class="feature-container feature-block-right-2">
        <div class="left">{left}</div>
        <div class="right">{right}</div>
      </div>
    </div>
  )
}

export default Feature
